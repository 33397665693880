<template>
  <div
    v-if="isVisible"
    class="relative hidden w-full flex-row justify-between before:absolute before:left-0 before:top-1/2 before:block before:h-[1px] before:w-full before:bg-navy-dark before:opacity-40 before:content-[''] lg:flex">
    <div
      v-for="item in breadcrumbs"
      :key="item.routeName"
      class="relative px-8 text-lg font-bold text-navy-dark"
      :class="backgroundColor">
      <span :class="isCurrent(item) ? 'underline' : 'opacity-40'">{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
  import validators from '$/validators.js'

  export default {
    name: 'AppBreadcrumbs',
    props: {
      currentRouteName: {
        type: String,
        required: true,
      },
      breadcrumbs: {
        type: Array,
        required: true,
        validator: (val) => {
          let valid = true

          if ((valid = validators.isArray(val))) {
            // assign and check
            for (const i of val) {
              valid = valid && validators.isValidString(i.label)
              valid = valid && validators.isValidString(i.routeName)
              if (!valid) break
            }
          }

          return valid
        },
      },
      backgroundColor: {
        type: String,
        required: false,
        default: 'bg-navy-light',
      },
    },
    computed: {
      isVisible() {
        // hide if the current route name is not in the provided breadcrumbs[]
        return this.breadcrumbs.find((i) => i.routeName === this.currentRouteName)
      },
      isCurrent() {
        return ({ routeName }) => this.currentRouteName === routeName
      },
    },
  }
</script>
