<template>
  <div>
    <div v-if="labelsAreloading">
      <Skeleton></Skeleton>
    </div>

    <div
      v-if="showBroadbandLabels && !labelsAreloading"
      class="w-full text-navy-dark">
      <div class="productLabel border-navy-dark px-4 pt-2 text-left">
        <h5 class="showBroadbandLabels text-2lg border-b-2 border-navy-dark">Broadband Facts</h5>
        <div class="text-sm font-bold">Breezeline</div>

        <div class="text-sm font-bold">
          {{ getProductLabelData(name)?.labels?.[0]?.name ?? '' }}
          {{ Number(getProductLabelData(name)?.labels?.[0]?.downloadSpeed ?? 0) / 1000 }}
        </div>
        <div class="mb-1 border-b border-navy-dark pb-1 text-sm font-normal">
          Fixed Broadband Consumer Disclosure
        </div>
        <div class="grid gap-3 text-xs lg:grid-cols-2">
          <div>
            <div
              class="mb-2 flex items-center justify-between border-b border-navy-dark text-sm font-bold">
              <span class="py-1 font-semibold">Monthly Price</span>

              <span>
                {{ fullPrice }}
              </span>
            </div>
            <div class="text-xs">
              <p class="mb-2 border-b border-navy-dark pb-2 font-normal">
                This Monthly Price is not an introductory rate<br />
                This Monthly Price does not require a contract
              </p>
              <div class="mb-1 border-b border-navy-dark">
                <span class="font-bold">Additional Charges & Terms</span>
                <span class="font-normal italic">may apply</span>
                <ul>
                  <li class="ml-3 font-bold">Provider Monthly Fees</li>
                  <li class="ml-3 flex justify-between font-normal">
                    <div>Equipment Lease</div>
                    <div>$17.99</div>
                  </li>
                  <li class="ml-3 flex justify-between font-normal">
                    <div>Additional WiFi Extender</div>
                    <div>$4.99</div>
                  </li>
                  <li class="ml-3 flex justify-between font-normal">
                    <div>Home Wire Service Plan</div>
                    <div>$9.99</div>
                  </li>
                  <li class="ml-3 flex justify-between font-normal">
                    <div>Paper Statement Fee</div>
                    <div>Varies</div>
                  </li>
                  <li class="ml-3 font-bold">One-time Fees at the Time of Purchase</li>
                  <li class="ml-3 flex justify-between font-normal">
                    <div>Professional Installation</div>
                    <div>$99.00</div>
                  </li>
                  <li class="ml-3 flex justify-between font-normal">
                    <div>Self-Installation (Direct Shipped)</div>
                    <div>No Charge</div>
                  </li>
                  <li class="ml-3 flex justify-between font-normal">
                    <div>Self-Installation (Tech Delivery)</div>
                    <div>$10.00</div>
                  </li>
                  <li class="ml-3 flex justify-between font-normal">
                    <div>Restart of Service</div>
                    <div>$40.00</div>
                  </li>
                  <li class="ml-3 flex justify-between font-normal">
                    <div>Early Termination Fee</div>
                    <div>N/A</div>
                  </li>
                  <li class="ml-3 flex justify-between font-normal">
                    <div>Government Taxes</div>
                    <div>Varies by Location</div>
                  </li>
                </ul>
              </div>
              <p class="mb-1 border-b border-navy-dark font-normal">
                <span class="block font-bold">Discounts & Bundles</span>
                $10 EcoSave/Auto Pay discount
                <a
                  href="https://www.breezeline.com/policies-agreements"
                  target="_blank"
                  class="text-navy-dark underline"
                  >Click Here</a
                >
                for more information on all of our discounts and bundles.
              </p>
            </div>
          </div>
          <div>
            <div class="mb-1 border-b border-navy-dark pb-1">
              <span class="font-bold">Speeds Provided with Plan</span>
              <ul>
                <li class="ml-3 flex justify-between">
                  <div class="font-normal">Typical Download Speed</div>
                  <div class="font-bold">
                    {{ getProductLabelData(name)?.plan?.typicalDownloadMbps ?? 0 }} Mbps
                  </div>
                </li>

                <li class="ml-3 flex justify-between">
                  <div class="font-normal">Typical Upload Speed</div>
                  <div class="font-bold">
                    {{ getProductLabelData(name)?.plan?.typicalUploadMbps ?? 0 }} Mbps
                  </div>
                </li>
                <li class="ml-3 flex justify-between">
                  <div class="font-normal">Typical Latency</div>
                  <div class="font-bold">
                    {{ getProductLabelData(name)?.plan?.typicalLatency ?? 0 }} ms
                  </div>
                </li>
              </ul>
            </div>
            <div class="mb-1 border-b border-navy-dark">
              <div class="flex justify-between font-bold">
                <div>Data Included with Monthly Price</div>
                <div>N/A</div>
              </div>
              <div class="flex justify-between font-normal">
                <div>Charges for Additional Data Usage</div>
                <div>N/A</div>
              </div>
            </div>
            <div class="mb-1 border-b border-navy-dark">
              <div class="flex justify-between">
                <div class="font-bold">Network Management</div>
                <div class="font-normal">
                  <a
                    href="https://www.breezeline.com/policies-agreements"
                    target="_blank"
                    >Read our Policy</a
                  >
                </div>
              </div>
              <div class="flex justify-between">
                <div class="font-bold">Privacy</div>
                <div class="font-normal">
                  <a
                    href="https://www.breezeline.com/policies-agreements"
                    target="_blank"
                    >Read our Policy</a
                  >
                </div>
              </div>
            </div>
            <div class="mb-1 border-b border-navy-dark">
              <div class="font-bold">Customer Support</div>
              <div class="font-normal">
                Contact Us:
                <a
                  class="text-navy-dark"
                  target="_blank"
                  href="https://www.breezeline.com/support"
                  >breezeline.com/support</a
                >
              </div>
              <div class="font-normal">
                <a
                  class="text-navy-dark"
                  href="tel:888-536-9600"
                  >888-536-9600</a
                >
              </div>
            </div>
            <p class="font-normal">
              Learn more about the terms used on this label by visiting the Federal Communications
              Commission Consumer Resource Center
            </p>
            <a
              class="mb-1 block text-right font-bold text-navy-dark"
              href="https://www.fcc.gov/consumers"
              target="_blank">
              fcc.gov/consumer
            </a>

            <div class="mb-1 font-normal">
              Unique Plan Identifier:<br />
              {{ getProductLabelData(name)?.labels?.[0]?.labelFrnIdentifier ?? '' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import Skeleton from './Skeleton.vue'
  export default {
    name: 'AppCart',
    components: {
      Skeleton,
    },
    props: {
      showBroadbandLabels: {
        type: Boolean,
        default: true,
      },
      name: {
        type: String,
        default: null,
      },
      download: {
        type: Number,
        default: 0,
      },
      upload: {
        type: Number,
        default: 0,
      },
      fullPrice: {
        type: Number,
        default: 0,
      },
    },
    data: () => ({
      frnNumber: null,
    }),
    computed: {
      ...mapGetters({
        internet: 'Buyflow/internet/selectedService',
        filterAndMerge: 'filterAndMerge',
        parseMetadata: 'parseMetadata',
      }),
      ...mapState('BroadbandLabels', ['labelData', 'labelsAreloading']),
      ...mapState({
        offerId: (state) => state.meta.offerId,
        marketType: (state) => state.meta.marketType,
      }),
    },
    mounted() {
      this.fetchBroadbandLabel({
        planName: this.name, // product name
        tier: this.download, // download speed
        downloadSpeed: this.download * 1000, // this.speed.download, // Download Speed
        uploadSpeed: this.getUploadSpeed() * 1000, // Upload Speed
        promotionalPriceDuration: 0, // Promo Months
        price: this.toCent(this.fullPrice), // EDP
        promotionalPrice: 0, // this.toCent(this.promoPrice), // Promo Price
        offerId: '0', // Offer ID from CSG
        marketCode: 'none', // Market Type
        hasPromotionalPrice: 'true', // True if promo price is different from EDP
      })
    },
    methods: {
      ...mapActions('BroadbandLabels', ['fetchBroadbandLabel', 'setLoadingState']),
      toCent(amount) {
        const str = amount.toString()
        const int = str.split('.')
        return Number(
          amount
            .toFixed(2)
            .replace('.', '')
            .padEnd(int.length === 1 ? 3 : 4, '0')
        )
      },
      getProductLabelData(offerName) {
        return this.labelData
          .flat()
          .find((data) => data?.labels?.some((label) => label.name === offerName))
      },
      getUploadSpeed() {
        switch (this.name) {
          case 'Base Internet':
            return 10
          case 'Unlimited Fast':
            return 20
          case 'Unlimited UltraFast':
            return 50
          case 'Unlimited GigaFast':
            return 50
          case 'Unlimited SuperFast':
            return 20
          case 'Unlimited Fiber Fast':
            return 200
          case 'Unlimited Fiber UltraFast':
            return 500
          case 'Unlimited Fiber GigaFast':
            return 600
          default:
            return this.upload
        }
      },

      setFrn() {
        this.frnNumber = this.getProductLabelData(this.name)?.labels?.[0]?.labelFrnIdentifier ?? ''
        this.$store.commit('BroadbandLabels/SET_PLAN_ID', this.frnNumber)
      },
    },
  }
</script>
