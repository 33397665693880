<template>
  <div class="flex flex-col gap-4 lg:gap-6">
    <div class="grid grid-rows-2 gap-4 lg:grid-cols-6 lg:grid-rows-1">
      <BzlInternetSpeedGauge
        class="col-span-3 justify-self-start"
        :speed="service.downloadSpeed"
        :title="`${service.downloadSpeed} Mbps`"
        :subtitle="`Ideal for: ${service.idealFor}`"
        :show-disclaimer="true"
        @on-disclaimer-click="$emit('on-internet-speed-disclaimer-click')"></BzlInternetSpeedGauge>
      <div
        class="col-span-3 flex w-full justify-between gap-2 justify-self-start leading-tight text-navy-dark">
        <div class="max-w-min sm:max-w-max">
          <div class="text-lg font-bold leading-tight lg:text-2xl">{{ service.title }}</div>
          <div class="text-sm">Unlimited Data</div>
        </div>
        <BzlPriceDisplay
          class="justify-self-end"
          :price="service.monthlyPrice"></BzlPriceDisplay>
      </div>
    </div>
    <hr />
    <div class="flex flex-row items-end justify-between">
      <div class="w-1/2">
        <BzlDropdown
          v-if="service.keyPoints.length"
          :distance="5">
          <template #trigger>
            <BzlButton
              slot="trigger"
              variant="default">
              View Details
            </BzlButton>
          </template>
          <div class="max-w-[82%] rounded-lg bg-black bg-opacity-85 p-4 text-white sm:max-w-full">
            <ul class="list-inside list-disc text-sm">
              <li
                v-for="i in service.keyPoints"
                :key="i">
                {{ i }}
              </li>
            </ul>
          </div>
        </BzlDropdown>
      </div>
      <div v-if="isSelectable">
        <BzlButton
          v-if="isSelected"
          variant="success"
          @click="$emit('on-unselect-service')">
          Selected
        </BzlButton>
        <BzlButton
          v-else
          @click="
            $emit('on-select-service')
            showBroadbandLabel(service.title)
            callSetFrn()
          ">
          Select
        </BzlButton>
      </div>
    </div>

    <div class="mb-8 text-center">
      <span class="font-light mr-2">Broadband Label</span>
      <label class="switch">
        <input
          v-model="isVisible"
          type="checkbox"
          checked />
        <span class="slider round"></span>
      </label>

      <AppBroadbandLabel
        v-show="isVisible"
        ref="LabelComp"
        :name="service.title"
        :download="service.downloadSpeed"
        :upload="service.uploadSpeed"
        :full-price="service.monthlyPrice"></AppBroadbandLabel>
    </div>
  </div>
</template>

<script>
  import { InternetService } from '$/InternetService.js'
  import AppBroadbandLabel from '../AppBroadbandLabel/index.vue'

  export default {
    name: 'AppInternetServiceItem',
    components: {
      AppBroadbandLabel,
    },
    props: {
      variant: {
        type: String,
        required: false,
        default: 'default',
        validator: (val) =>
          [
            'default', // wide, stacked vertically, used in panels/accordions
            'tight', // todo: used in offer cards
          ].includes(val),
      },
      service: {
        type: InternetService,
        required: true,
      },
      isSelectable: {
        type: Boolean,
        required: false,
        default: true,
      },
      isSelected: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: () => ({
      broadbandLabels: {},
      isVisible: true,
    }),

    methods: {
      showBroadbandLabel(serviceTitle) {
        this.$set(this.broadbandLabels, serviceTitle, true)
      },
      callSetFrn() {
        this.$refs.LabelComp.setFrn()
      },
    },
  }
</script>
