import BaseInternetModems from './ModemSpeeds/BaseInternet.js'
import SelectFast30025Modems from './ModemSpeeds/SelectFast30025.js'
import SelectFast60050Modems from './ModemSpeeds/SelectFast60050.js'
import SelectGigaFast100050Modems from './ModemSpeeds/SelectGigaFast100050.js'
import UnlimitedFastModems from './ModemSpeeds/UnlimitedFast.js'
import UnlimitedGigaFastModems from './ModemSpeeds/UnlimitedGigaFast.js'
import UnlimitedSuperFastModems from './ModemSpeeds/UnlimitedSuperFast.js'
import UnlimitedUltraFastModems from './ModemSpeeds/UnlimitedUltraFast.js'
import FiberEquipment from './ModemSpeeds/Fiber.js'

export default {
  'base-internet': {
    id: 'base-internet',
    title: 'Base',
    downloadSpeed: 100,
    uploadSpeed: 50,
    monthlyPrice: 19.99,
    idealFor: 'Up to 3 devices',
    keyPoints: ['No data caps, contracts, or hidden fees', 'Great for email and web browsing'],
    modems: BaseInternetModems,
    titleInCart: 'Base Internet',
  },
  'select-fast-30025': {
    id: 'select-fast-30025',
    title: 'Select Fast 300/25',
    downloadSpeed: 300,
    uploadSpeed: 25,
    monthlyPrice: 0.99,
    idealFor: 'Up to 6 devices',
    keyPoints: [
      'No Data caps, contracts or hidden fees',
      'Best value for light to moderate internet use',
      'Fast speeds for the whole family  ',
    ],
    modems: SelectFast30025Modems,
    titleInCart: 'Select Fast 300',
  },
  'select-fast-60025': {
    id: 'select-fast-60025',
    title: 'Select Fast 600/25',
    downloadSpeed: 600,
    uploadSpeed: 25,
    monthlyPrice: 0.99,
    idealFor: 'Up to 11 devices',
    keyPoints: [
      'No Data caps, contracts or hidden fees',
      'More speed for streaming, fast downloads and work and learn from home',
      'Increased speeds for the connected home',
    ],
    modems: SelectFast60050Modems,
    titleInCart: 'Select Fast 600',
  },
  'select-gigafast-100050': {
    id: 'select-gigafast-100050',
    title: 'Select GigaFast 1000/50',
    modems: SelectGigaFast100050Modems,
    downloadSpeed: 1000,
    uploadSpeed: 50,
    monthlyPrice: 0.99,
    idealFor: '12+ devices',
    keyPoints: [
      'No Data caps, contracts or hidden fees',
      'Best for streaming, gaming and work and learn from home',
      'Our fastest speeds for the always connected home with maximum internet needs',
    ],
    titleInCart: 'Select GigaFast',
  },
  'unlimited-fast': {
    id: 'unlimited-fast',
    title: 'Unlimited Fast',
    downloadSpeed: 200,
    uploadSpeed: 50,
    monthlyPrice: 39.99,
    idealFor: 'Up to 6 devices',
    keyPoints: [
      'No data caps, contracts, or hidden fees',
      'Best for email and web browsing on multiple devices',
      'Fast speeds for the whole family',
    ],
    modems: UnlimitedFastModems,
    titleInCart: 'Unlimited Fast',
  },
  'unlimited-gigafast': {
    id: 'unlimited-gigafast',
    title: 'Unlimited GigaFast',
    downloadSpeed: 1000,
    uploadSpeed: 50,
    monthlyPrice: 59.99,
    idealFor: '12+ devices',
    keyPoints: [
      'No data caps, contracts, or hidden fees',
      'Best for streaming, work and learn from home',
      'Our fastest speeds for the always connected home',
    ],
    modems: UnlimitedGigaFastModems,
    titleInCart: 'Unlimited GigaFast',
  },
  'unlimited-superfast': {
    id: 'unlimited-superfast',
    title: 'Unlimited SuperFast',
    modems: UnlimitedSuperFastModems,
    downloadSpeed: 250,
    uploadSpeed: 50,
    monthlyPrice: 0.99,
    idealFor: 'Up to 7 devices',
    keyPoints: [
      'No Data caps, contracts or hidden fees',
      'Best value for light to moderate internet use',
      'Fast speeds for the whole family',
    ],
    titleInCart: 'Unlimited SuperFast',
  },
  'unlimited-ultrafast': {
    id: 'unlimited-ultrafast',
    title: 'UltraFast',
    downloadSpeed: 500,
    uploadSpeed: 50,
    monthlyPrice: 39.99,
    idealFor: 'Up to 11 devices',
    keyPoints: [
      'No data caps, contracts, or hidden fees',
      'Great for streaming, emailing and web browsing',
      'Fast speeds for the whole family',
    ],
    modems: UnlimitedUltraFastModems,
    titleInCart: 'Unlimited UltraFast',
  },
  'unlimited-fiber-gigafast': {
    id: 'unlimited-fiber-gigafast',
    title: 'Unlimited Fiber GigaFast',
    downloadSpeed: 1000,
    uploadSpeed: 50,
    monthlyPrice: 39.99,
    idealFor: '12+ devices',
    keyPoints: [
      'No data caps, contracts, or hidden fees',
      'Best for streaming, gaming and work and learn from home',
      'Our fastest speeds for the always connected home with maximum internet needs',
    ],
    modems: FiberEquipment,
    titleInCart: 'Unlimited Fiber GigaFast',
  },
  'unlimited-fiber-ultrafast': {
    id: 'unlimited-fiber-ultrafast',
    title: 'Unlimited Fiber UltraFast',
    downloadSpeed: 500,
    uploadSpeed: 50,
    monthlyPrice: 39.99,
    idealFor: 'Up to 11 devices',
    keyPoints: [
      'No data caps, contracts, or hidden fees',
      'More speed for streaming, gaming, and working and learning from home',
      'Super fast upload and download for heavy internet users',
    ],
    modems: FiberEquipment,
    titleInCart: 'Unlimited Fiber UltraFast',
  },
  'unlimited-fiber-fast': {
    id: 'unlimited-fiber-fast',
    title: 'Unlimited Fiber Fast',
    downloadSpeed: 300,
    uploadSpeed: 50,
    monthlyPrice: 39.99,
    idealFor: 'Up to 6 devices',
    keyPoints: [
      'No data caps, contracts, or hidden fees',
      'Best value with the speed and reliability of fiber',
      'Super fast upload and download for multiple users ',
    ],
    modems: FiberEquipment,
    titleInCart: 'Unlimited Fiber Fast',
  },
}
