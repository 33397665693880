<template>
  <main class="space-y-6 pb-32">
    <div
      v-if="currentService"
      class="space-y-4">
      <BzlTitlePair centered>
        <template #title>Change Your Speed</template>
      </BzlTitlePair>
      <AppPanel
        :open="true"
        label="Current Speed">
        <div class="flex flex-row justify-center">
          <BzlInternetSpeedGauge
            :speed="currentService.downloadSpeed"
            :title="`${currentService.downloadSpeed} Mbps`"
            :subtitle="`Ideal for: ${currentService.idealFor}`"
            :show-disclaimer="true"
            @on-disclaimer-click="speedDisclaimerClicked"></BzlInternetSpeedGauge>
        </div>
        <template #footnote>
          <p>
            Please be aware that if you are on promotional pricing you will lose your promotional
            discount.
          </p>
        </template>
      </AppPanel>
    </div>

    <div class="space-y-4">
      <BzlTitlePair centered>
        <template #title>Select A Plan Below</template>
        <template #subtitle
          >Your speed change will go into effect in one to two days, or when the new modem is
          installed (if required).</template
        >
      </BzlTitlePair>
      <template v-for="speedGroup in allSpeeds">
        <AppPanel
          v-if="speedGroup.speeds.length > 0"
          :key="speedGroup.label"
          :collapsible="speedGroup.collapsible"
          :open="speedGroup.open"
          :label="speedGroup.label"
          @sl-show="speedGroup.event">
          <div class="space-y-4">
            <AppPanel
              v-for="speed of speedGroup.speeds"
              :key="speed.id"
              variant="alternate">
              <template
                v-if="requiresModemUpgrade(speed, $store.state.account.modem)"
                #warning>
                Requires Modem Upgrade
                <BzlIconButton
                  name="info-circle-fill"
                  label="More Information"
                  class="text-sm opacity-80"
                  @click="modemDisclaimerClicked"></BzlIconButton>
              </template>
              <AppInternetServiceItem
                :service="speed"
                :is-selected="isSelected(speed)"
                :requires-modem-upgrade="requiresModemUpgrade(speed, $store.state.account.modem)"
                v-on="$listeners"
                @on-select-service="setService(speed)"
                @on-unselect-service="setService(null)">
              </AppInternetServiceItem>
            </AppPanel>
          </div>
        </AppPanel>
      </template>
    </div>

    <AppPanel variant="blue-da-ba-dee">
      <BzlTitlePair centered>
        <template #title>
          <span class="text-white">Find Your Perfect Speed</span>
        </template>
        <template #subtitle>
          <span class="text-white">
            Just answer a few simple questions and we'll make a recommendation.
          </span>
        </template>
      </BzlTitlePair>
      <div class="text-center">
        <BzlButton
          href="https://www.breezeline.com/lets-get-started"
          variant="default"
          target="_blank"
          @click="speedQuizClicked">
          Take The Speed Quiz
        </BzlButton>
      </div>
    </AppPanel>

    <AppWizardNav color="dark">
      <a
        :href="$store.getters['myAccountUrl']"
        target="_self"
        class="flex items-center gap-2 px-4 text-white hover:underline">
        <BzlIcon name="house-door-fill"></BzlIcon>
        My Account
      </a>
      <BzlButton
        variant="default"
        size="large"
        :disabled="isSelected(currentService)"
        @click="onNextPage">
        Continue
        <BzlIcon name="arrow-right"></BzlIcon>
      </BzlButton>
    </AppWizardNav>
  </main>
</template>

<script>
  export default {
    name: 'ChangeYourSpeedPage',
    data: () => ({
      isLoading: false,
    }),
    computed: {
      currentService() {
        return this.$store.state.internet.currentService
      },
      availableServices() {
        return this.$store.state.internet.availableServices
      },
      // note: this applies to all faster speeds, so we probably don't need this anymore
      // requiresModemUpgrade() {
      //   return this.$store.state.internet.requiresModemUpgrade
      // },
      requiresModemUpgrade: () => (speed, modem) => {
        return !speed.modems.includes(modem)
      },
      fasterSpeeds() {
        return this.availableServices
          .filter((s) => s.downloadSpeed > this.currentService.downloadSpeed)
          .sort((x, y) => (x.downloadSpeed < y.downloadSpeed ? 1 : -1)) // faster ones first
      },
      slowerSpeeds() {
        return this.availableServices
          .filter((s) => s.downloadSpeed < this.currentService.downloadSpeed)
          .sort((x, y) => (x.downloadSpeed < y.downloadSpeed ? 1 : -1)) // faster ones first
      },
      selectedService() {
        return this.$store.state.cart.services.internet
      },
      isSelected() {
        return (s) => s.id === this.selectedService?.id
      },
      allSpeeds() {
        return [
          {
            label: 'Speed Up',
            speeds: this.fasterSpeeds,
            collapsible: this.slowerSpeeds.length > 0,
            open: true,
            event() {},
          },
          {
            label: 'Slow Down',
            speeds: this.slowerSpeeds,
            collapsible: this.fasterSpeeds.length > 0,
            open: false,
            event: () => {
              this.trackEvent('OS_SLOW_DOWN_CLICK')
            },
          },
        ]
      },
    },
    mounted() {
      this.trackEvent('trigger_change_your_speed_clicked')

      const event = window.gtag?.events.OS_CURRENT_SPEED
      if (event) {
        if (this.$store.state.account.currentPlan?.down) {
          event[2] = this.$store.state.account.currentPlan?.down
        } else {
          event[2] = 'speed not available'
        }

        this.trackEvent(event)
      }
    },
    methods: {
      speedQuizClicked() {
        this.trackEvent('OS_TAKE_SPEED_QUIZ')
      },
      viewSlowerSpeeds() {
        this.trackEvent('OS_SLOW_DOWN_CLICK')
      },
      speedDisclaimerClicked() {
        this.$emit('on-internet-speed-disclaimer-click')
      },
      modemDisclaimerClicked() {
        this.trackEvent('OS_TOOLTIP_CLICK')
        this.$emit('on-modem-upgrade-disclaimer-click')
      },
      setService(service = null) {
        this.$store.commit(
          'internet/SET_REQUIRES_MODEM_UPGRADE',
          this.requiresModemUpgrade(service, this.$store.state.account.modem)
        )

        return this.$store.dispatch('cart/setService', { type: 'internet', service })
      },
      async onNextPage() {
        this.$store.dispatch('lockApp')

        try {
          await this.$store.dispatch(
            'internet/submitInternet',
            this.$store.state.cart.services.internet
          )

          await this.$store.dispatch('order/determineCredits')
          await this.$store.dispatch('order/updateState')
        } catch (error) {
          // restart the app if we have an invalid conversationId. the session
          // has expired
          if (error.cause === 'conversationId') {
            this.$store.dispatch('restartUpgrade', {})
            return
          }

          if (error === 'IPR22E-TIVO EQUIP REQS PRO-INSTALL') {
            // service needs pro install, what do we do?
          }

          if (error === 'IBK07E-ORDER PACKAGE MISMATCH WITH 9XX;	 CCS Field in error:-QTY') {
            // capture it and report it to sentry
          }

          this.$store.dispatch('unlockApp')
          this.$store.dispatch('handleError', error)
          this.$router.push({ name: 'manual-upgrade' })

          return
        }

        // track new speed
        const event = window.gtag?.events.OS_CONTINUE_NEW_SPEED

        if (event) {
          event[2] = this.$store.state.cart.services.internet.downloadSpeed
          this.trackEvent(event)
        }

        if (this.$store.state.internet.requiresModemUpgrade) {
          this.$router.push({ name: 'hardware-requirement' })
        } else {
          this.$router.push({ name: 'cart' })
        }

        this.$store.dispatch('unlockApp')
      },
    },
  }
</script>

<style scoped>
  .BzlIconButton::part(base) {
    @apply text-navy-dark;
  }
</style>
